import classNames from 'classnames/bind';
import { memo } from 'react';
import IconPlay from '../../assets/svg/play.svg';

import styles from './PlayBackIcon.css';
import I18n from '../../lang';

const cx = classNames.bind(styles);

function PlayBackIcon() {
  const { t } = I18n.useTranslation();

  return (
    <div
      className={cx(
        'playBackIcon',
        'playBackIcon--isDetailV5',
        'globalPlaybackButton'
      )}
    >
      <div className={styles.playBackIcon__background}>
        <IconPlay title={t('Icon.play')} />
      </div>
    </div>
  );
}

PlayBackIcon.displayName = 'PlayBackIcon';

export default memo(PlayBackIcon);
